import React from 'react';
import SlideHomePage from './components/SlideHomePage';
import FirstBannerHomePage from './components/FirstBannerHomePage';
import NewArrival from './components/NewArrival';
import DealOfTheDay from './components/DealOfTheDay';
import SecondBanner from './components/SecondBanner';
import BestSeller from './components/BestSeller';
import InfoHomePage from './components/InfoHomePage';
import SlidePostInsta from './components/SlidePostInsta';

const HomePage = () => {
    return (
        <React.Fragment>
            <div className="fullwidth-template">
                <SlideHomePage />
                <FirstBannerHomePage/>
                <NewArrival />
                <DealOfTheDay />
                <SecondBanner /> 
                <BestSeller />
                <InfoHomePage />
                <SlidePostInsta />
            </div>

        </React.Fragment>
    );
};

export default HomePage;