import React from 'react';

const Footer = () => {
    return (
        <React.Fragment>
  
                <footer id="footer" className="footer style-04">
                    <div className="section-001 section-009">
                        <div className="container">
                            <div className="lynessa-newsletter style-01">
                                <div className="newsletter-inner">
                                    <div className="newsletter-info">
                                        <div className="newsletter-wrap">
                                            <h3 className="title">Bản tin | Giảm giá<span /></h3>
                                            {/* <h4 className="subtitle">Giảm giá 30%</h4> */}
                                            <p className="desc">Nhận thông tin các chương trình của Lynessa</p>
                                        </div>
                                    </div>
                                    <div className="newsletter-form-wrap">
                                        <div className="newsletter-form-inner">
                                            <input className="email email-newsletter" name="email" placeholder="Nhập Email của bạn ..." type="email" />
                                            <a href="#" className="button btn-submit submit-newsletter">
                                                <span className="text">Đăng Ký</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-025">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 d-lg-none">
                                    <div className="logo-footer">
                                        <img src="assets/images/logo.png" className="az_single_image-img attachment-full" alt="img" />
                                    </div>
                                    <div className="footer-desc">Lynessa helps you sell anything.<br />
                                        The best choice for your next personal
                                    </div>
                                    <div className="lynessa-socials style-01">
                                        <div className="content-socials">
                                            <ul className="socials-list">
                                                <li>
                                                    <a href="https://facebook.com/" target="_blank">
                                                        <i className="fa fa-facebook" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/" target="_blank">
                                                        <i className="fa fa-instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://twitter.com/" target="_blank">
                                                        <i className="fa fa-twitter" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.pinterest.com/" target="_blank">
                                                        <i className="fa fa-pinterest-p" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="lynessa-listitem style-04">
                                        <div className="listitem-inner">
                                            <h4 className="title">Khách Hàng </h4>
                                            <ul className="listitem-list">
                                                <li>
                                                    <a href="#" target=" _blank">
                                                        Vận chuyển &amp; Đổi trả </a>
                                                </li>
                                                <li>
                                                    <a href="#" target="_self">
                                                        Mua sắm </a>
                                                </li>
                                                <li>
                                                    <a href="#" target="_self">
                                                        Chính sách bảo hành </a>
                                                </li>
                                                <li>
                                                    <a href="#" target="_self">
                                                        Chính sách thanh toán</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-none d-lg-block">
                                    <div className="logo-footer">
                                        <img src="assets/images/logo.png" className="az_single_image-img attachment-full" alt="img" />
                                    </div>
                                    <div className="footer-desc">Lynessa giúp bạn bán bất cứ sản phẩm nào. <br />
                                        Lựa chọn tốt nhất cho lựa chọn cá nhân tiếp theo của bạn
                                    </div>
                                    <div className="lynessa-socials style-01">
                                        <div className="content-socials">
                                            <ul className="socials-list">
                                                <li>
                                                    <a href="https://facebook.com/" target="_blank">
                                                        <i className="fa fa-facebook" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/" target="_blank">
                                                        <i className="fa fa-instagram" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://twitter.com/" target="_blank">
                                                        <i className="fa fa-twitter" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.pinterest.com/" target="_blank">
                                                        <i className="fa fa-pinterest-p" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="lynessa-listitem style-04">
                                        <div className="listitem-inner">
                                            <h4 className="title">
                                                Giới thiệu </h4>
                                            <ul className="listitem-list">
                                                <li>
                                                    <a href="#" target="_self">
                                                       Về Lynessa </a>
                                                </li>

                                                <li>
                                                    <a href="#" target="_self">
                                                        Hệ thống cửa hàng </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-016">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <p>©Lynessa. All Rights Reserved.</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="payment">
                                        <img src="assets/images/payment.png" className="az_single_image-img attachment-full" alt="img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="footer-device-mobile">
                    <div className="wapper">
                        <div className="footer-device-mobile-item device-home">
                            <a href="index-2.html">
                                <span className="icon">
                                    <span className="pe-7s-home" />
                                </span>
                                Home
                            </a>
                        </div>
                        <div className="footer-device-mobile-item device-home device-wishlist">
                            <a href="wishlist.html">
                                <span className="icon">
                                    <span className="pe-7s-like" />
                                </span>
                                Wishlist
                            </a>
                        </div>
                        <div className="footer-device-mobile-item device-home device-cart">
                            <a href="cart.html">
                                <span className="icon">
                                    <span className="pe-7s-shopbag" />
                                    <span className="count-icon">
                                        0
                                    </span>
                                </span>
                                <span className="text">Cart</span>
                            </a>
                        </div>
                        <div className="footer-device-mobile-item device-home device-user">
                            <a href="my-account.html">
                                <span className="icon">
                                    <span className="pe-7s-user" />
                                </span>
                                Account
                            </a>
                        </div>
                    </div>
                </div>
                <a href="#" className="backtotop active">
                    <i className="fa fa-angle-up" />
                </a>
      

        </React.Fragment>
    );
};

export default Footer;