import React from 'react';

const FirstBannerHomePage = () => {
    return (
        <React.Fragment>
              <div className="section-001 section-004">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <div className="lynessa-banner style-04 left-center">
                                    <div className="banner-inner">
                                        <figure className="banner-thumb">
                                            <img src="assets/images/banner244.jpg" className="attachment-full size-full" alt="img" />
                                        </figure>
                                        <div className="banner-info ">
                                            <div className="banner-content">
                                                <div className="title-wrap">
                                                    <h6 className="title">Sản phẩm bán chạy</h6>
                                                </div>
                                                <div className="cate"> </div>
                                                <div className="button-wrap">
                                                    <div className="subtitle">Giảm giá mạnh</div>
                                                    <a className="button" target="_self" href="#"><span>Mua ngay</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div className="lynessa-banner style-04 left-center">
                                    <div className="banner-inner">
                                        <figure className="banner-thumb">
                                            <img src="assets/images/banner252.jpg" className="attachment-full size-full" alt="img" />
                                        </figure>
                                        <div className="banner-info ">
                                            <div className="banner-content">
                                                <div className="title-wrap">
                                                    <h6 className="title">Bộ sưu tập mới</h6>
                                                </div>
                                                <div className="cate"></div>
                                                <div className="button-wrap">
                                                    <div className="subtitle">Available Now</div>
                                                    <a className="button" target="_self" href="#"><span>Mua ngay</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
};

export default FirstBannerHomePage;