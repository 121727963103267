import React from 'react';

const SecondBanner = () => {
    return (
       <React.Fragment>
          <div className="section-013 section-001">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="lynessa-banner style-03 left-center">
                                    <div className="banner-inner">
                                        <figure className="banner-thumb">
                                            <img src="assets/images/banner21.jpg" className="attachment-full size-full" alt="img" /></figure>
                                        <div className="banner-info ">
                                            <div className="banner-content">
                                                <div className="title-wrap">
                                                    <h6 className="title">
                                                        <a target="_self" href="#">Xem ngay</a>
                                                    </h6>
                                                </div>
                                                <div className="cate">Xu hướng</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="lynessa-banner style-03 left-center">
                                    <div className="banner-inner">
                                        <figure className="banner-thumb">
                                            <img src="assets/images/banner201.jpg" className="attachment-full size-full" alt="img" /></figure>
                                        <div className="banner-info ">
                                            <div className="banner-content">
                                                <div className="title-wrap">
                                                    <h6 className="title">
                                                        <a target="_self" href="#">Xem ngay</a>
                                                    </h6>
                                                </div>
                                                <div className="cate">
                                                    Mới
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="lynessa-banner style-03 left-center">
                                    <div className="banner-inner">
                                        <figure className="banner-thumb">
                                            <img src="assets/images/banner23.jpg" className="attachment-full size-full" alt="img" /></figure>
                                        <div className="banner-info ">
                                            <div className="banner-content">
                                                <div className="title-wrap">
                                                    <h6 className="title">
                                                        <a target="_self" href="#">Xem ngay</a>
                                                    </h6>
                                                </div>
                                                <div className="cate">
                                                    Giảm giá
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
       </React.Fragment>
    );
};

export default SecondBanner;