import React from 'react';

const InfoHomePage = () => {
    return (
        <React.Fragment>
            <div className="section-014">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <div className="lynessa-iconbox style-02">
                                <div className="iconbox-inner">
                                    <div className="icon">
                                        <span className="pe-7s-rocket" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Giao hàng toàn quốc</h4>
                                        <div className="desc">Cung cấp dịch vụ giao hàng toàn quốc, đảm bảo sản phẩm của bạn được đưa đến mọi nơi một cách nhanh chóng và an toàn.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="lynessa-iconbox style-02">
                                <div className="iconbox-inner">
                                    <div className="icon">
                                        <span className="pe-7s-unlock" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">An toàn khi mua sắm</h4>
                                        <div className="desc">Thanh toán bằng các phương thức thanh toán phổ biến và an toàn.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="lynessa-iconbox style-02">
                                <div className="iconbox-inner">
                                    <div className="icon">
                                        <span className="pe-7s-piggy" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">30 ngày đổi trả</h4>
                                        <div className="desc">Sản phẩm sẽ được đổi trả hoặc hoàn tiền trong 30 ngày đầu nếu sản phẩm lỗi.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="lynessa-iconbox style-02">
                                <div className="iconbox-inner">
                                    <div className="icon">
                                        <span className="pe-7s-help2" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Mua sắm tin cậy</h4>
                                        <div className="desc">Chính sách bảo vệ người mua của chúng tôi bao gồm mọi giao dịch từ khi bạn nhấp chuột cho đến khi nhận hàng.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default InfoHomePage;