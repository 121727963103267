import React from 'react';

const DealOfTheDay = () => {
    return (
       <React.Fragment>
              <div className="section-012">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="lynessa-heading style-01">
                                    <div className="heading-inner">
                                        <h3 className="title">
                                        Ưu đãi ngày hôm nay<span /></h3>
                                        <div className="subtitle">
                                            Nunc mauris enim, pretium quis orci eget.
                                        </div>
                                    </div>
                                </div>
                                <div className="lynessa-products style-03">
                                    <div className="response-product product-list-owl owl-slick equal-container better-height" data-slick="{&quot;arrows&quot;:true,&quot;slidesMargin&quot;:30,&quot;dots&quot;:true,&quot;infinite&quot;:false,&quot;speed&quot;:300,&quot;slidesToShow&quot;:2,&quot;rows&quot;:1}" data-responsive="[{&quot;breakpoint&quot;:480,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:768,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:992,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:1200,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:1500,&quot;settings&quot;:{&quot;slidesToShow&quot;:2,&quot;slidesMargin&quot;:&quot;30&quot;}}]">
                                        <div className="product-item on_sale style-03 rows-space-0 post-36 product type-product status-publish has-post-thumbnail product_cat-table product_cat-bed product_tag-light product_tag-table product_tag-sock first instock sale shipping-taxable purchasable product-type-simple" data-slick-index={0} style={{ marginRight: 30, width: 520 }} aria-hidden="false" tabIndex={0} role="tabpanel" id="slick-slide10" aria-describedby="slick-slide-control10">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={0}>
                                                        <img className="img-responsive" src="assets/images/apro51012-1-275x310.jpg" alt="SPORTY HOODIE" width={275} height={310} />
                                                    </a>
                                                </div>
                                                <div className="product-info equal-elem">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={0}>SPORTY HOODIE</a>
                                                    </h3>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>125.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>99.00</span></ins></span>
                                                    <div className="process-valiable">
                                                        <div className="valiable-text">
                                                            <span className="text">
                                                                13% Already Sold              </span>
                                                            <span className="text">
                                                                Available:                     <span>
                                                                    21                  </span>
                                                            </span>
                                                        </div>
                                                        <span className="valiable-total total">
                                                            <span className="process" style={{ width: '13%' }} />
                                                        </span>
                                                    </div>
                                                    <div className="countdown-product">
                                                        <div className="lynessa-countdown" data-datetime="03/21/2021 12:00:00"><span className="days"><span className="number">136</span><span className="text">Days</span></span><span className="hour"><span className="number">21</span><span className="text">Hours</span></span><span className="mins"><span className="number">55</span><span className="text">Mins</span></span><span className="secs"><span className="number">44</span><span className="text">Secs</span></span>
                                                        </div>
                                                    </div>
                                                    <div className="add-to-cart">
                                                        <a href="https://dreamingtheme.kiendaotac.com/lynessa/home-02/?add-to-cart=36" data-quantity={1} className="button product_type_simple add_to_cart_button ajax_add_to_cart" data-product_id={36} data-product_sku="098J812-1" aria-label="Add “SPORTY HOODIE” to your cart" rel="nofollow" tabIndex={0}>Add to cart</a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item on_sale style-03 rows-space-0 post-32 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-hat product_tag-sock  instock sale featured shipping-taxable purchasable product-type-simpl" data-slick-index={1} style={{ marginRight: 30, width: 520 }} aria-hidden="false" tabIndex={0} role="tabpanel" id="slick-slide11" aria-describedby="slick-slide-control11">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={0}>
                                                        <img className="img-responsive" src="assets/images/apro71-1-275x310.jpg" alt="MINI SKIRT" width={275} height={310} />
                                                    </a>
                                                </div>
                                                <div className="product-info equal-elem">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={0}>MINI SKIRT</a>
                                                    </h3>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>109.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>89.00</span></ins></span>
                                                    <div className="process-valiable">
                                                        <div className="valiable-text">
                                                            <span className="text">
                                                                50% Already Sold              </span>
                                                            <span className="text">
                                                                Available:                     <span>
                                                                    15                  </span>
                                                            </span>
                                                        </div>
                                                        <span className="valiable-total total">
                                                            <span className="process" style={{ width: '50%' }} />
                                                        </span>
                                                    </div>
                                                    <div className="countdown-product">
                                                        <div className="lynessa-countdown" data-datetime="03/12/2021 12:00:00"><span className="days"><span className="number">127</span><span className="text">Days</span></span><span className="hour"><span className="number">21</span><span className="text">Hours</span></span><span className="mins"><span className="number">55</span><span className="text">Mins</span></span><span className="secs"><span className="number">44</span><span className="text">Secs</span></span>
                                                        </div>
                                                    </div>
                                                    <div className="add-to-cart">
                                                        <a href="https://dreamingtheme.kiendaotac.com/lynessa/home-02/?add-to-cart=32" data-quantity={1} className="button product_type_simple add_to_cart_button ajax_add_to_cart" data-product_id={32} data-product_sku="009U199" aria-label="Add “MINI SKIRT” to your cart" rel="nofollow" tabIndex={0}>Add to cart</a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item on_sale style-03 rows-space-0 post-28 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-light product_tag-sock last instock sale featured shipping-taxable purchasable product-type-simple" data-slick-index={2} style={{ marginRight: 30, width: 520 }} aria-hidden="true" tabIndex={-1} role="tabpanel" id="slick-slide12" aria-describedby="slick-slide-control12">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro1211-2-275x310.jpg" alt="SLEEVELESS TOP" width={275} height={310} />
                                                    </a>
                                                </div>
                                                <div className="product-info equal-elem">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>SLEEVELESS TOP</a>
                                                    </h3>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>138.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>119.00</span></ins></span>
                                                    <div className="process-valiable">
                                                        <div className="valiable-text">
                                                            <span className="text">
                                                                9% Already Sold              </span>
                                                            <span className="text">
                                                                Available:                     <span>
                                                                    21                  </span>
                                                            </span>
                                                        </div>
                                                        <span className="valiable-total total">
                                                            <span className="process" style={{ width: '9%' }} />
                                                        </span>
                                                    </div>
                                                    <div className="countdown-product">
                                                        <div className="lynessa-countdown" data-datetime="11/29/2020 12:00:00"><span className="days"><span className="number">389</span><span className="text">Days</span></span><span className="hour"><span className="number">21</span><span className="text">Hours</span></span><span className="mins"><span className="number">55</span><span className="text">Mins</span></span><span className="secs"><span className="number">44</span><span className="text">Secs</span></span>
                                                        </div>
                                                    </div>
                                                    <div className="add-to-cart">
                                                        <a href="https://dreamingtheme.kiendaotac.com/lynessa/home-02/?add-to-cart=28" data-quantity={1} className="button product_type_simple add_to_cart_button ajax_add_to_cart" data-product_id={28} data-product_sku="005K938" aria-label="Add “SLEEVELESS TOP” to your cart" rel="nofollow" tabIndex={-1}>Add to cart</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
       </React.Fragment>
    );
};

export default DealOfTheDay;