import React from 'react';

const NewArrival = () => {
    return (
      <React.Fragment>
               <div className="section-011">
                    <div className="container">
                        <div className="lynessa-heading style-01">
                            <div className="heading-inner">
                                <h3 className="title">
                                Sản phẩm mới <span /> </h3>
                                <div className="subtitle">
                                    Những sản phẩm mới nhất
                                </div>
                            </div>
                        </div>
                        <div className="lynessa-products style-04">
                            <div className="response-product product-list-owl owl-slick equal-container better-height" data-slick="{&quot;arrows&quot;:true,&quot;slidesMargin&quot;:30,&quot;dots&quot;:true,&quot;infinite&quot;:false,&quot;speed&quot;:300,&quot;slidesToShow&quot;:4,&quot;rows&quot;:1}" data-responsive="[{&quot;breakpoint&quot;:480,&quot;settings&quot;:{&quot;slidesToShow&quot;:2,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:768,&quot;settings&quot;:{&quot;slidesToShow&quot;:2,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:992,&quot;settings&quot;:{&quot;slidesToShow&quot;:3,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:1200,&quot;settings&quot;:{&quot;slidesToShow&quot;:3,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:1500,&quot;settings&quot;:{&quot;slidesToShow&quot;:4,&quot;slidesMargin&quot;:&quot;30&quot;}}]">
                                <div className="product-item recent-product style-04 rows-space-0 post-93 product type-product status-publish has-post-thumbnail product_cat-light product_cat-table product_cat-new-arrivals product_tag-table product_tag-sock first instock shipping-taxable purchasable product-type-simple  ">
                                    <div className="product-inner tooltip-top tooltip-all-top">
                                        <div className="product-thumb">
                                            <a className="thumb-link" href="#" tabIndex={0}>
                                                <img className="img-responsive" src="assets/images/apro13-1-270x350.jpg" alt="KNIT LIKE" width={270} height={350} />
                                            </a>
                                            <div className="flash">
                                                <span className="onnew"><span className="text">New</span></span></div>
                                            <div className="group-button">
                                                <div className="add-to-cart">
                                                    <a href="#" className="button product_type_simple add_to_cart_button ajax_add_to_cart">Add to
                                                        cart</a></div>
                                                <a href="#" className="button yith-wcqv-button">Quick View</a>
                                                <div className="lynessa product compare-button">
                                                    <a href="#" className="compare button">Compare</a>
                                                </div>
                                                <div className="yith-wcwl-add-to-wishlist">
                                                    <div className="yith-wcwl-add-button show">
                                                        <a href="#" className="add_to_wishlist">Add to Wishlist</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-info">
                                            <h3 className="product-name product_title">
                                                <a href="#" tabIndex={0}>KNIT LIKE</a>
                                            </h3>
                                            <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>109.00</span></span>
                                            <div className="rating-wapper nostar">
                                                <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                <span className="review">(0)</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-item recent-product style-04 rows-space-0 post-49 product type-product status-publish has-post-thumbnail product_cat-light product_cat-bed product_cat-sofas product_tag-multi product_tag-lamp  instock shipping-taxable purchasable product-type-simple  ">
                                    <div className="product-inner tooltip-top tooltip-all-top">
                                        <div className="product-thumb">
                                            <a className="thumb-link" href="#" tabIndex={0}>
                                                <img className="img-responsive" src="assets/images/apro302-270x350.jpg" alt="MARIE CAMI" width={270} height={350} />
                                            </a>
                                            <div className="flash">
                                                <span className="onnew"><span className="text">New</span></span></div>
                                            <div className="group-button">
                                                <div className="add-to-cart">
                                                    <a href="#" className="button product_type_simple add_to_cart_button ajax_add_to_cart">Add to
                                                        cart</a></div>
                                                <a href="#" className="button yith-wcqv-button">Quick View</a>
                                                <div className="lynessa product compare-button">
                                                    <a href="#" className="compare button">Compare</a>
                                                </div>
                                                <div className="yith-wcwl-add-to-wishlist">
                                                    <div className="yith-wcwl-add-button show">
                                                        <a href="#" className="add_to_wishlist">Add to Wishlist</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-info">
                                            <h3 className="product-name product_title">
                                                <a href="#" tabIndex={0}>MARIE CAMI</a>
                                            </h3>
                                            <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>79.00</span></span>
                                            <div className="rating-wapper nostar">
                                                <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                <span className="review">(0)</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-item recent-product style-04 rows-space-0 post-37 product type-product status-publish has-post-thumbnail product_cat-chair product_cat-bed product_tag-light product_tag-hat product_tag-sock last instock shipping-taxable purchasable product-type-simple  ">
                                    <div className="product-inner tooltip-top tooltip-all-top">
                                        <div className="product-thumb">
                                            <a className="thumb-link" href="#" tabIndex={0}>
                                                <img className="img-responsive" src="assets/images/apro31-1-270x350.jpg" alt="KNIT CARDIGAN" width={270} height={350} />
                                            </a>
                                            <div className="flash">
                                                <span className="onnew"><span className="text">New</span></span></div>
                                            <div className="group-button">
                                                <div className="add-to-cart">
                                                    <a href="#" className="button product_type_simple add_to_cart_button ajax_add_to_cart">Add to
                                                        cart</a></div>
                                                <a href="#" className="button yith-wcqv-button">Quick View</a>
                                                <div className="lynessa product compare-button">
                                                    <a href="#" className="compare button">Compare</a>
                                                </div>
                                                <div className="yith-wcwl-add-to-wishlist">
                                                    <div className="yith-wcwl-add-button show">
                                                        <a href="#" className="add_to_wishlist">Add to Wishlist</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-info">
                                            <h3 className="product-name product_title">
                                                <a href="#" tabIndex={0}>KNIT CARDIGAN</a>
                                            </h3>
                                            <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>120.00</span></span>
                                            <div className="rating-wapper nostar">
                                                <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                <span className="review">(0)</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-item recent-product style-04 rows-space-0 post-35 product type-product status-publish has-post-thumbnail product_cat-chair product_cat-new-arrivals product_cat-lamp product_tag-light product_tag-hat product_tag-sock first instock shipping-taxable purchasable product-type-simple  ">
                                    <div className="product-inner tooltip-top tooltip-all-top">
                                        <div className="product-thumb">
                                            <a className="thumb-link" href="#" tabIndex={0}>
                                                <img className="img-responsive" src="assets/images/apro41-1-270x350.jpg" alt="MARIE CAMI" width={270} height={350} />
                                            </a>
                                            <div className="flash">
                                                <span className="onnew"><span className="text">New</span></span></div>
                                            <div className="group-button">
                                                <div className="add-to-cart">
                                                    <a href="#" className="button product_type_simple add_to_cart_button ajax_add_to_cart">Add to
                                                        cart</a></div>
                                                <a href="#" className="button yith-wcqv-button">Quick View</a>
                                                <div className="lynessa product compare-button">
                                                    <a href="#" className="compare button">Compare</a>
                                                </div>
                                                <div className="yith-wcwl-add-to-wishlist">
                                                    <div className="yith-wcwl-add-button show">
                                                        <a href="#" className="add_to_wishlist">Add to Wishlist</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-info">
                                            <h3 className="product-name product_title">
                                                <a href="#" tabIndex={0}>MARIE CAMI</a>
                                            </h3>
                                            <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>134.00</span></span>
                                            <div className="rating-wapper nostar">
                                                <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                <span className="review">(0)</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-item recent-product style-04 rows-space-0 post-36 product type-product status-publish has-post-thumbnail product_cat-table product_cat-bed product_tag-light product_tag-table product_tag-sock  instock sale shipping-taxable purchasable product-type-simple">
                                    <div className="product-inner tooltip-top tooltip-all-top">
                                        <div className="product-thumb">
                                            <a className="thumb-link" href="#" tabIndex={-1}>
                                                <img className="img-responsive" src="assets/images/apro51012-1-270x350.jpg" alt="SPORTY HOODIE" width={270} height={350} />
                                            </a>
                                            <div className="flash">
                                                <span className="onsale"><span className="number">-21%</span></span>
                                                <span className="onnew"><span className="text">New</span></span></div>
                                            <div className="group-button">
                                                <div className="add-to-cart">
                                                    <a href="#" className="button product_type_simple add_to_cart_button ajax_add_to_cart">Add to
                                                        cart</a></div>
                                                <a href="#" className="button yith-wcqv-button">Quick View</a>
                                                <div className="lynessa product compare-button">
                                                    <a href="#" className="compare button">Compare</a>
                                                </div>
                                                <div className="yith-wcwl-add-to-wishlist">
                                                    <div className="yith-wcwl-add-button show">
                                                        <a href="#" className="add_to_wishlist">Add to Wishlist</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-info">
                                            <h3 className="product-name product_title">
                                                <a href="#" tabIndex={-1}>SPORTY HOODIE</a>
                                            </h3>
                                            <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>125.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>99.00</span></ins></span>
                                            <div className="rating-wapper nostar">
                                                <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                <span className="review">(0)</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-item recent-product style-04 rows-space-0 post-34 product type-product status-publish has-post-thumbnail product_cat-light product_cat-new-arrivals product_tag-light product_tag-hat product_tag-sock last instock sale featured shipping-taxable product-type-grouped">
                                    <div className="product-inner tooltip-top tooltip-all-top">
                                        <div className="product-thumb">
                                            <a className="thumb-link" href="#" tabIndex={-1}>
                                                <img className="img-responsive" src="assets/images/apro61-1-270x350.jpg" alt="INSERT TANK" width={270} height={350} />
                                            </a>
                                            <div className="flash">
                                                <span className="onnew"><span className="text">New</span></span></div>
                                            <div className="group-button">
                                                <div className="add-to-cart">
                                                    <a href="#" className="button product_type_simple add_to_cart_button ajax_add_to_cart">View
                                                        products</a></div>
                                                <a href="#" className="button yith-wcqv-button">Quick View</a>
                                                <div className="lynessa product compare-button">
                                                    <a href="#" className="compare button">Compare</a>
                                                </div>
                                                <div className="yith-wcwl-add-to-wishlist">
                                                    <div className="yith-wcwl-add-button show">
                                                        <a href="#" className="add_to_wishlist">Add to Wishlist</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-info">
                                            <h3 className="product-name product_title">
                                                <a href="#" tabIndex={-1}>INSERT TANK</a>
                                            </h3>
                                            <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>79.00</span> – <span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>139.00</span></span>
                                            <div className="rating-wapper nostar">
                                                <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                <span className="review">(0)</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
      </React.Fragment>
    );
};

export default NewArrival;