import React from 'react';

const BestSeller = () => {
    return (
     <React.Fragment>
           <div className="section-007">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-4">
                                <div className="lynessa-products style-06">
                                    <h3 className="title">
                                        <span>Bán chạy nhất</span>
                                    </h3>
                                    <div className="response-product product-list-owl owl-slick equal-container better-height" data-slick="{&quot;arrows&quot;:true,&quot;slidesMargin&quot;:30,&quot;dots&quot;:false,&quot;infinite&quot;:false,&quot;speed&quot;:300,&quot;slidesToShow&quot;:1,&quot;rows&quot;:3}" data-responsive="[{&quot;breakpoint&quot;:480,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:768,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:992,&quot;settings&quot;:{&quot;slidesToShow&quot;:2,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:1200,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:1500,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;30&quot;}}]">
                                        <div className="product-item best-selling style-06 rows-space-30 post-25 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-specials product_tag-light product_tag-sock first instock sale featured shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={0}>
                                                        <img className="img-responsive" src="assets/images/apro151-1-90x90.jpg" alt="HEM SMOCKING" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={0}>HEM SMOCKING</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>89.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>79.00</span></ins></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item best-selling style-06 rows-space-30 post-23 product type-product status-publish has-post-thumbnail product_cat-chair product_cat-lamp product_cat-sofas product_tag-hat  instock shipping-taxable purchasable product-type-variable has-default-attributes">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={0}>
                                                        <img className="img-responsive" src="assets/images/apro171-1-90x90.jpg" alt="INSERT SINGLET" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={0}>INSERT SINGLET</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>105.00</span> – <span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>110.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item best-selling style-06 rows-space-30 post-32 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-hat product_tag-sock last instock sale featured shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={0}>
                                                        <img className="img-responsive" src="assets/images/apro71-1-90x90.jpg" alt="MINI SKIRT" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={0}>MINI SKIRT</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>109.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>89.00</span></ins></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item best-selling style-06 rows-space-30 post-20 product type-product status-publish has-post-thumbnail product_cat-light product_cat-new-arrivals product_cat-specials product_tag-table product_tag-hat product_tag-sock first instock sale featured shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro201-1-90x90.jpg" alt="ELOISE SHIRRED" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>ELOISE SHIRRED</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>150.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>139.00</span></ins></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item best-selling style-06 rows-space-30 post-36 product type-product status-publish has-post-thumbnail product_cat-table product_cat-bed product_tag-light product_tag-table product_tag-sock  instock sale shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro51012-1-90x90.jpg" alt="SPORTY HOODIE" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>SPORTY HOODIE</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>125.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>99.00</span></ins></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item best-selling style-06 rows-space-30 post-49 product type-product status-publish has-post-thumbnail product_cat-light product_cat-bed product_cat-sofas product_tag-multi product_tag-lamp last instock shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro302-90x90.jpg" alt="MARIE CAMI" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>MARIE CAMI</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>79.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item best-selling style-06 rows-space-30 post-93 product type-product status-publish has-post-thumbnail product_cat-light product_cat-table product_cat-new-arrivals product_tag-table product_tag-sock first instock shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro13-1-90x90.jpg" alt="KNIT LIKE" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>KNIT LIKE</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>109.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item best-selling style-06 rows-space-30 post-28 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-light product_tag-sock  instock sale featured shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro1211-2-90x90.jpg" alt="SLEEVELESS TOP" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>SLEEVELESS TOP</a>
                                                    </h3>
                                                    <div className="rating-wapper ">
                                                        <div className="star-rating"><span style={{ width: '100%' }}>Rated <strong className="rating">5.00</strong> out of 5</span></div>
                                                        <span className="review">(1)</span></div>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>138.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>119.00</span></ins></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item best-selling style-06 rows-space-30 post-22 product type-product status-publish has-post-thumbnail product_cat-table product_cat-bed product_cat-lamp product_tag-table product_tag-hat product_tag-sock last instock featured downloadable shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro181-2-90x90.jpg" alt="BASIC SINGLET" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>BASIC SINGLET</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>98.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <div className="lynessa-products style-06">
                                    <h3 className="title">
                                        <span>Xếp hạng cao nhất</span>
                                    </h3>
                                    <div className="response-product product-list-owl owl-slick equal-container better-height" data-slick="{&quot;arrows&quot;:true,&quot;slidesMargin&quot;:30,&quot;dots&quot;:false,&quot;infinite&quot;:false,&quot;speed&quot;:300,&quot;slidesToShow&quot;:1,&quot;rows&quot;:3}" data-responsive="[{&quot;breakpoint&quot;:480,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:768,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:992,&quot;settings&quot;:{&quot;slidesToShow&quot;:2,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:1200,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:1500,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;30&quot;}}]">
                                        <div className="product-item top-rated style-06 rows-space-30 post-26 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-light product_tag-hat first instock featured shipping-taxable product-type-external">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={0}>
                                                        <img className="img-responsive" src="assets/images/apro141-1-90x90.jpg" alt="ELOISE SHIRRED" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={0}>ELOISE SHIRRED</a>
                                                    </h3>
                                                    <div className="rating-wapper ">
                                                        <div className="star-rating"><span style={{ width: '100%' }}>Rated <strong className="rating">5.00</strong> out of 5</span></div>
                                                        <span className="review">(1)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>207.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item top-rated style-06 rows-space-30 post-28 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-light product_tag-sock  instock sale featured shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={0}>
                                                        <img className="img-responsive" src="assets/images/apro1211-2-90x90.jpg" alt="SLEEVELESS TOP" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={0}>SLEEVELESS TOP</a>
                                                    </h3>
                                                    <div className="rating-wapper ">
                                                        <div className="star-rating"><span style={{ width: '100%' }}>Rated <strong className="rating">5.00</strong> out of 5</span></div>
                                                        <span className="review">(1)</span></div>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>138.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>119.00</span></ins></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item top-rated style-06 rows-space-30 post-32 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-hat product_tag-sock last instock sale featured shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={0}>
                                                        <img className="img-responsive" src="assets/images/apro71-1-90x90.jpg" alt="MINI SKIRT" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={0}>MINI SKIRT</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>109.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>89.00</span></ins></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item top-rated style-06 rows-space-30 post-20 product type-product status-publish has-post-thumbnail product_cat-light product_cat-new-arrivals product_cat-specials product_tag-table product_tag-hat product_tag-sock first instock sale featured shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro201-1-90x90.jpg" alt="ELOISE SHIRRED" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>ELOISE SHIRRED</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>150.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>139.00</span></ins></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item top-rated style-06 rows-space-30 post-33 product type-product status-publish has-post-thumbnail product_cat-chair product_cat-table product_cat-lamp product_tag-light product_tag-table product_tag-hat  instock shipping-taxable purchasable product-type-variable has-default-attributes">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro83-1-90x90.jpg" alt="Glasses" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>WIDE PANTS</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>56.00</span> – <span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>60.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item top-rated style-06 rows-space-30 post-21 product type-product status-publish has-post-thumbnail product_cat-light product_cat-bed product_cat-lamp product_tag-light product_tag-sock last outofstock featured shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro191-1-90x90.jpg" alt="WIDE PANTS" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>WIDE PANTS</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>35.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item top-rated style-06 rows-space-30 post-34 product type-product status-publish has-post-thumbnail product_cat-light product_cat-new-arrivals product_tag-light product_tag-hat product_tag-sock first instock sale featured shipping-taxable product-type-grouped">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro61-1-90x90.jpg" alt="INSERT TANK" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>INSERT TANK</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>79.00</span> – <span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>139.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item top-rated style-06 rows-space-30 post-22 product type-product status-publish has-post-thumbnail product_cat-table product_cat-bed product_cat-lamp product_tag-table product_tag-hat product_tag-sock  instock featured downloadable shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro181-2-90x90.jpg" alt="BASIC SINGLET" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>BASIC SINGLET</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>98.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item top-rated style-06 rows-space-30 post-35 product type-product status-publish has-post-thumbnail product_cat-chair product_cat-new-arrivals product_cat-lamp product_tag-light product_tag-hat product_tag-sock last instock shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro41-1-90x90.jpg" alt="MARIE CAMI" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>MARIE CAMI</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>134.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <div className="lynessa-products style-06">
                                    <h3 className="title">
                                        <span>Nổi bật</span>
                                    </h3>
                                    <div className="response-product product-list-owl owl-slick equal-container better-height" data-slick="{&quot;arrows&quot;:true,&quot;slidesMargin&quot;:30,&quot;dots&quot;:false,&quot;infinite&quot;:false,&quot;speed&quot;:300,&quot;slidesToShow&quot;:1,&quot;rows&quot;:3}" data-responsive="[{&quot;breakpoint&quot;:480,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:768,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:992,&quot;settings&quot;:{&quot;slidesToShow&quot;:2,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:1200,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;20&quot;}},{&quot;breakpoint&quot;:1500,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;30&quot;}}]">
                                        <div className="product-item featured_products style-06 rows-space-30 post-34 product type-product status-publish has-post-thumbnail product_cat-light product_cat-new-arrivals product_tag-light product_tag-hat product_tag-sock first instock sale featured shipping-taxable product-type-grouped">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={0}>
                                                        <img className="img-responsive" src="assets/images/apro61-1-90x90.jpg" alt="INSERT TANK" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={0}>INSERT TANK</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>79.00</span> – <span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>139.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item featured_products style-06 rows-space-30 post-32 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-hat product_tag-sock  instock sale featured shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={0}>
                                                        <img className="img-responsive" src="assets/images/apro71-1-90x90.jpg" alt="MINI SKIRT" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={0}>MINI SKIRT</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>109.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>89.00</span></ins></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item featured_products style-06 rows-space-30 post-30 product type-product status-publish has-post-thumbnail product_cat-light product_cat-bed product_cat-specials product_tag-light product_tag-table product_tag-sock last instock featured downloadable shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={0}>
                                                        <img className="img-responsive" src="assets/images/apro101-1-90x90.jpg" alt="STRIPE SKIRTS" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={0}>STRIPE SKIRTS</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>60.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item featured_products style-06 rows-space-30 post-31 product type-product status-publish has-post-thumbnail product_cat-light product_cat-sofas product_tag-hat first instock sale featured shipping-taxable product-type-grouped">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro91-1-90x90.jpg" alt="Swing Dress" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>Swing Dress</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>89.00</span> – <span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>139.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item featured_products style-06 rows-space-30 post-29 product type-product status-publish has-post-thumbnail product_cat-new-arrivals product_cat-specials product_tag-light product_tag-sock  instock featured downloadable shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro1113-90x90.jpg" alt="Abstract Sweatshirt" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>Abstract Sweatshirt</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>129.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item featured_products style-06 rows-space-30 post-28 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-light product_tag-sock last instock sale featured shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro1211-2-90x90.jpg" alt="SLEEVELESS TOP" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>SLEEVELESS TOP</a>
                                                    </h3>
                                                    <div className="rating-wapper ">
                                                        <div className="star-rating"><span style={{ width: '100%' }}>Rated <strong className="rating">5.00</strong> out of 5</span></div>
                                                        <span className="review">(1)</span></div>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>138.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>119.00</span></ins></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item featured_products style-06 rows-space-30 post-26 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-light product_tag-hat first instock featured shipping-taxable product-type-external">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro141-1-90x90.jpg" alt="ELOISE SHIRRED" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>ELOISE SHIRRED</a>
                                                    </h3>
                                                    <div className="rating-wapper ">
                                                        <div className="star-rating"><span style={{ width: '100%' }}>Rated <strong className="rating">5.00</strong> out of 5</span></div>
                                                        <span className="review">(1)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>207.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item featured_products style-06 rows-space-30 post-25 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-specials product_tag-light product_tag-sock  instock sale featured shipping-taxable purchasable product-type-simple">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro151-1-90x90.jpg" alt="HEM SMOCKING" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>HEM SMOCKING</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><del><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>89.00</span></del> <ins><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>79.00</span></ins></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-item featured_products style-06 rows-space-30 post-24 product type-product status-publish has-post-thumbnail product_cat-chair product_cat-table product_cat-new-arrivals product_tag-light product_tag-hat product_tag-sock last instock featured shipping-taxable purchasable product-type-variable has-default-attributes">
                                            <div className="product-inner">
                                                <div className="product-thumb">
                                                    <a className="thumb-link" href="#" tabIndex={-1}>
                                                        <img className="img-responsive" src="assets/images/apro161-1-90x90.jpg" alt="MINI SKIRT" width={90} height={90} />
                                                    </a>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name product_title">
                                                        <a href="#" tabIndex={-1}>MINI SKIRT</a>
                                                    </h3>
                                                    <div className="rating-wapper nostar">
                                                        <div className="star-rating"><span style={{ width: '0%' }}>Rated <strong className="rating">0</strong> out of 5</span></div>
                                                        <span className="review">(0)</span></div>
                                                    <span className="price"><span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>45.00</span> – <span className="lynessa-Price-amount amount"><span className="lynessa-Price-currencySymbol">$</span>54.00</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
     </React.Fragment>
    );
};

export default BestSeller;