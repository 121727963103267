import React from 'react';

const SlidePostInsta = () => {
    return (
        <React.Fragment>
             <div className="section-008">
                    <div className="lynessa-instagram style-01">
                        <div className="instagram-owl owl-slick" data-slick="{&quot;arrows&quot;:false,&quot;slidesMargin&quot;:15,&quot;dots&quot;:false,&quot;infinite&quot;:false,&quot;speed&quot;:300,&quot;slidesToShow&quot;:5,&quot;rows&quot;:1}" data-responsive="[{&quot;breakpoint&quot;:480,&quot;settings&quot;:{&quot;slidesToShow&quot;:2,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:768,&quot;settings&quot;:{&quot;slidesToShow&quot;:3,&quot;slidesMargin&quot;:&quot;10&quot;}},{&quot;breakpoint&quot;:992,&quot;settings&quot;:{&quot;slidesToShow&quot;:4,&quot;slidesMargin&quot;:&quot;15&quot;}},{&quot;breakpoint&quot;:1200,&quot;settings&quot;:{&quot;slidesToShow&quot;:4,&quot;slidesMargin&quot;:&quot;15&quot;}},{&quot;breakpoint&quot;:1500,&quot;settings&quot;:{&quot;slidesToShow&quot;:5,&quot;slidesMargin&quot;:&quot;15&quot;}}]">
                            <div className="rows-space-0">
                                <a target="_blank" href="#" className="item" tabIndex={0}>
                                    <img className="img-responsive lazy" src="assets/images/insta1.jpg" alt="Home 01" />
                                    <span className="instagram-info">
                                        <span className="social-wrap">
                                            <span className="social-info">1
                                                <i className="pe-7s-chat" />
                                            </span>
                                            <span className="social-info">0
                                                <i className="pe-7s-like2" />
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                            <div className="rows-space-0">
                                <a target="_blank" href="#" className="item" tabIndex={0}>
                                    <img className="img-responsive lazy" src="assets/images/insta2.jpg" alt="Home 01" />
                                    <span className="instagram-info">
                                        <span className="social-wrap">
                                            <span className="social-info">0
                                                <i className="pe-7s-chat" />
                                            </span>
                                            <span className="social-info">0
                                                <i className="pe-7s-like2" />
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                            <div className="rows-space-0">
                                <a target="_blank" href="#" className="item" tabIndex={0}>
                                    <img className="img-responsive lazy" src="assets/images/insta3.jpg" alt="Home 01" />
                                    <span className="instagram-info">
                                        <span className="social-wrap">
                                            <span className="social-info">0
                                                <i className="pe-7s-chat" />
                                            </span>
                                            <span className="social-info">0
                                                <i className="pe-7s-like2" />
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                            <div className="rows-space-0">
                                <a target="_blank" href="#" className="item" tabIndex={0}>
                                    <img className="img-responsive lazy" src="assets/images/insta4.jpg" alt="Home 01" />
                                    <span className="instagram-info">
                                        <span className="social-wrap">
                                            <span className="social-info">0
                                                <i className="pe-7s-chat" />
                                            </span>
                                            <span className="social-info">0
                                                <i className="pe-7s-like2" />
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                            <div className="rows-space-0">
                                <a target="_blank" href="#" className="item" tabIndex={0}>
                                    <img className="img-responsive lazy" src="assets/images/insta5.jpg" alt="Home 01" />
                                    <span className="instagram-info">
                                        <span className="social-wrap">
                                            <span className="social-info">0
                                                <i className="pe-7s-chat" />
                                            </span>
                                            <span className="social-info">0
                                                <i className="pe-7s-like2" />
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                            <div className="rows-space-0">
                                <a target="_blank" href="#" className="item" tabIndex={-1}>
                                    <img className="img-responsive lazy" src="assets/images/insta6.jpg" alt="Home 01" />
                                    <span className="instagram-info">
                                        <span className="social-wrap">
                                            <span className="social-info">0
                                                <i className="pe-7s-chat" />
                                            </span>
                                            <span className="social-info">0
                                                <i className="pe-7s-like2" />
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                            <div className="rows-space-0">
                                <a target="_blank" href="#" className="item" tabIndex={-1}>
                                    <img className="img-responsive lazy" src="assets/images/insta7.jpg" alt="Home 01" />
                                    <span className="instagram-info">
                                        <span className="social-wrap">
                                            <span className="social-info">0
                                                <i className="pe-7s-chat" />
                                            </span>
                                            <span className="social-info">0
                                                <i className="pe-7s-like2" />
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                            <div className="rows-space-0">
                                <a target="_blank" href="#" className="item" tabIndex={-1}>
                                    <img className="img-responsive lazy" src="assets/images/insta8.jpg" alt="Home 01" />
                                    <span className="instagram-info">
                                        <span className="social-wrap">
                                            <span className="social-info">0
                                                <i className="pe-7s-chat" />
                                            </span>
                                            <span className="social-info">0
                                                <i className="pe-7s-like2" />
                                            </span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
};

export default SlidePostInsta;