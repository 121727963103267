import React from 'react';

const SlideHomePage = () => {
    return (
        <React.Fragment>
               <div className="slide-home-02">
                    <div className="response-product product-list-owl owl-slick equal-container better-height" data-slick="{&quot;arrows&quot;:false,&quot;slidesMargin&quot;:0,&quot;dots&quot;:true,&quot;infinite&quot;:false,&quot;speed&quot;:300,&quot;slidesToShow&quot;:1,&quot;rows&quot;:1}" data-responsive="[{&quot;breakpoint&quot;:480,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;0&quot;}},{&quot;breakpoint&quot;:768,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;0&quot;}},{&quot;breakpoint&quot;:992,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;0&quot;}},{&quot;breakpoint&quot;:1200,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;0&quot;}},{&quot;breakpoint&quot;:1500,&quot;settings&quot;:{&quot;slidesToShow&quot;:1,&quot;slidesMargin&quot;:&quot;0&quot;}}]">
                        <div className="slide-wrap">
                            <img src="assets/images/slide21.jpg" alt="image" />
                            <div className="slide-info">
                                <div className="container">
                                    <div className="slide-inner">
                                        <h5>Sale up to <span>40%</span> Off</h5>
                                        <h1>The Summer</h1>
                                        <h2>This Week Only</h2>
                                        <a href="#">Shop now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide-wrap">
                            <img src="assets/images/slide22.jpg" alt="image" />
                            <div className="slide-info">
                                <div className="container">
                                    <div className="slide-inner">
                                        <h5>Hurry Up !</h5>
                                        <h1>New Arrival</h1>
                                        <h2>For Your Just In</h2>
                                        <a href="#">Shop now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide-wrap">
                            <img src="assets/images/slide23.jpg" alt="image" />
                            <div className="slide-info">
                                <div className="container">
                                    <div className="slide-inner">
                                        <h5>Special Offer</h5>
                                        <h1>Flash Sale</h1>
                                        <h2>Up to <span>70%</span> Off</h2>
                                        <a href="#">Shop now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
};

export default SlideHomePage;